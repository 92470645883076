@import url(https://oomnium.com/fonts/font-face.css);

vdr-relation-card img.preview {
  max-height: 100px;
  max-width: 100px;
}

vdr-relation-product-input .preview img, .ng-dropdown-panel-items img {
  max-height: 50px;
  max-width: 50px;
}

vdr-chip {
  margin-right: 4px;
}

vdr-ab-right {
  gap: 0.5rem;
}

.ProseMirror-menubar, .ck.ck-editor__top,  {
  // hack to keep prosemirror bar in viewing area
  top: 60px !important;
  position: sticky !important;
  z-index: 2!important;
}


.ck.ck-sticky-panel__content_sticky {
  top: 0   !important;
  position: absolute !important;
  z-index: 2!important;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.ck.ck-editor {
  width: 860px !important;
}

.ProseMirror-menubar {
  border-bottom: 1px solid var(--color-component-border-200) !important;
}

.ck.ck-sticky-panel__content {
  border-bottom: 1px solid var(--ck-color-base-border) !important;
}

.ck.ck-content, .ck.ck-button .ck.ck-button__label {
  font-family: "GT Eesti Text", system-ui, sans-serif;
}

.ck h1, .ck.ck-button.ck-heading_heading1  {
  font-size: 2rem;
  font-weight: 500;
  line-height: 112.5%;
}


.ck h2, .ck.ck-button.ck-heading_heading2 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 133%;

}

.ck h3, .ck.ck-button.ck-heading_heading3 {
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 133%;
}

.ck h4, .ck.ck-button.ck-heading_heading4 {
  font-size: 22px;
  font-weight: 600;
}


.ck.ck-content {

  figure:not(image_resized) {
      width: 100%
  }

  h1, h2, h3, h4 {
    font-family: "GT Eesti Text", system-ui, sans-serif;
    color: #00222A;
  }

  p, li {
    font-size: 22px;
    color: #00222A;
    font-weight: 375;
    line-height: 133%;
  }

  li {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }

  ul {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.5555556em;
  }
}

[ng-reflect-label='Customer Remark'],
[ng-reflect-label='Company Remark']  {
  textarea {
    height: 6rem;
  }
}

vdr-product-detail2 vdr-form-field:has([ng-reflect-name="description"]) {
  display: none;
}
